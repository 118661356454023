<!-- eslint-disable vue/no-unused-vars -->
<template>
  <v-container id="regular-tables-view" fluid tag="section">
    <v-row no-gutters>
      <v-col cols="12" sm="3">
        <v-select
          v-model="selectedFranchise"
          :items="franchises"
          :item-text="getFullFranchiseName"
          item-value="Name"
          label="Select Franchise"
          persistent-hint
          return-object
          solo
          v-on:change="filterEmployees"
        ></v-select>
      </v-col>
      <v-col cols="12" sm="3">
        <v-select
          class="ml-3"
          v-model="selectedPriorityStatus"
          :items="priorities"
          item-text="Name"
          item-value="Name"
          label="Select Priority"
          persistent-hint
          return-object
          solo
          v-on:change="filterEmployees"
        ></v-select>
      </v-col>
      <v-col cols="12" sm="2">
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              large
              class="ml-2  mt-1"
              @click="clearSearch()"
              color="info"
              v-bind="attrs"
              v-on="on"
            >
              mdi-close-circle
            </v-icon>
          </template>
          <span>Clear</span>
        </v-tooltip>
      </v-col>
    </v-row>

    <progress-spinner :isLoading="loading" />

    <v-row>
      <v-col cols="12">
        <v-card>
          <v-data-table
            v-model="selectedItems"
            :headers="headers"
            :items="rosterData"
            sort-by="FirstName"
            item-key="Id"
            show-select
            class="elevation-1"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>Lead Roster Employees</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
                <v-dialog
                  :retain-focus="false"
                  v-model="dialogDelete"
                  max-width="300px"
                >
                  <v-card>
                    <v-card-title class="text-h5">{{
                      deleteFormTitle
                    }}</v-card-title>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="closeDelete"
                        >Cancel</v-btn
                      >
                      <v-btn
                        v-if="singleDelete"
                        color="blue darken-1"
                        text
                        @click="deleteItemConfirm"
                        >OK</v-btn
                      >
                      <v-btn
                        v-else
                        color="blue darken-1"
                        text
                        @click="bulkRemovePriority"
                        >OK</v-btn
                      >
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>

            <template v-slot:item.Name="{ item }"
              >{{ item.FirstName }} {{ item.LastName }}</template
            >
            <template v-slot:item.PriorityName="{ item }">
              <v-btn
                small
                class="ma-1"
                color="success"
                text-color="white"
                @click="editItem(item)"
              >
                {{ item.PriorityStatus.Name }}
              </v-btn>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-btn
                small
                class="mr-2"
                @click="editItem(item)"
                color="primary"
              >
                Change
              </v-btn>
              <v-btn small @click="deleteItem(item)" color="warning">
                Remove
              </v-btn>
            </template>
          </v-data-table>
          <div class="mt-n12 pl-3">
            <v-btn class="mx-2"  small color="primary" @click="toggleSingleAssign()">
              Change
            </v-btn>
            <v-btn class="mx-2" small color="warning" @click="toggleSingleDelete()">
              Remove
            </v-btn>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-data-table
            :headers="withoutPriorityStatusHeaders"
            :items="employeesWithoutPriorityStatus"
            v-model="selectedItems"
            sort-by="FirstName"
            item-key="Id"
            show-select
            class="elevation-1"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title
                  >Employees not listed as Lead Eligible</v-toolbar-title
                >
                <v-divider class="mx-4" inset vertical></v-divider>
                <p class="warning" style="font-size: small; color: white">
                  &nbsp; You must indicate a priority status for any employee to
                  be included in the Appointment Setting system. &nbsp;
                </p>
                <v-spacer></v-spacer>
                <v-dialog
                  :retain-focus="false"
                  v-model="dialog"
                  max-width="470px"
                >
                  <v-card>
                    <v-card-title>
                      <span class="text-h5">{{ assignFormTitle }}</span>
                    </v-card-title>

                    <v-card-text style="height: 200px">
                      <v-radio-group v-model="priorityChange" column>
                        <v-radio
                          v-for="priority in priorities"
                          :key="priority.Id"
                          :label="priority.Name"
                          :value="priority.Id"
                        ></v-radio>
                      </v-radio-group>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="close">
                        Cancel
                      </v-btn>
                      <v-btn
                        v-if="singleAssign"
                        color="blue darken-1"
                        text
                        @click="assignPriorityStatusToEmployee"
                      >
                        Save
                      </v-btn>
                      <v-btn
                        v-else
                        color="blue darken-1"
                        text
                        @click="bulkAssignPriority"
                      >
                        Save
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>

            <template v-slot:item.Name="{ item }"
              >{{ item.FirstName }} {{ item.LastName }}</template
            >
            <template v-slot:item.actions="{ item }">
              <v-btn
                small
                class="mr-2"
                @click="editItem(item)"
                color="primary"
              >
                Add
              </v-btn>
            </template>
          </v-data-table>
          <div class="mt-n12 pl-3">
            <v-btn class="mx-2" small color="primary" @click="toggleSingleAssign()">
              Add
            </v-btn>
          </div>
        </v-card>
      </v-col>
    </v-row>

    <div class="py-3" />
  </v-container>
</template>

<script>
const axios = require("axios");
export default {
  name: "Roster",
  data() {
    return {
      singleSelect: false,
      rosterData: [],
      employees: [],
      singleAssign: true,
      singleDelete: true,
      selectedItems: [],
      employeesWithoutPriorityStatus: [],
      priorities: [],
      franchises: [],
      selectedPriorityStatus: null,
      selectedFranchise: null,
      page: 1,
      pageSize: 10,
      priorityChange: null,
      deleteId: [],
      loading: true,
      emp: {
        employeeIds: [],
      },

      headers: [
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "Name",
        },
        { text: "Role", value: "Role.Name" },
        { text: "PriorityName", value: "PriorityName" },
        { text: "WorkEmail", value: "WorkEmail" },
        { text: "FranchiseName", value: "Franchise.Name" },
        { text: "FranchiseCode", value: "Franchise.Code" },
        { text: "Actions", value: "actions", sortable: false },
      ],

      withoutPriorityStatusHeaders: [
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "Name",
        },
        { text: "Role", value: "Role.Name" },
        { text: "WorkEmail", value: "WorkEmail" },
        { text: "FranchiseName", value: "Franchise.Name" },
        { text: "FranchiseCode", value: "Franchise.Code" },
        {
          text: "Add Priority",
          align: "center",
          value: "actions",
          sortable: false,
        },
      ],

      editedIndex: -1,
      editedItem: {},
      defaultItem: {},
      dialog: false,
      dialogDelete: false,
    };
  },

  methods: {
    async getEmployees() {
      this.loading = true;
      this.employees = [];
      this.priorities = [];
      try {
        let { data } = await axios.get(`employees/with-priority-status`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        });
        this.employees = data;
        this.rosterData = data;
      } catch (err) {
        this.$errorHandler(err);
      } finally {
        this.loading = false;
      }
    },

    clearSearch() {
      this.selectedPriorityStatus = null;
      this.selectedFranchise = null;
      this.rosterData = this.employees;
    },

    filterEmployees() {
      let filtered = null;
      if (this.selectedPriorityStatus && this.selectedFranchise) {
        filtered = this.employees.filter(
          (item) =>
            item.PriorityStatus.Id == this.selectedPriorityStatus.Id &&
            item.Franchise.Id == this.selectedFranchise.Id
        );
      } else if (this.selectedFranchise) {
        filtered = this.employees.filter(
          (item) => item.Franchise.Id == this.selectedFranchise.Id
        );
      } else {
        filtered = this.employees.filter(
          (item) => item.PriorityStatus.Id == this.selectedPriorityStatus.Id
        );
      }
      this.rosterData = filtered;
    },

    async getWithoutPriorityStatus() {
      this.loading = true;
      this.employeesWithoutPriorityStatus = [];
      try {
        let { data } = await axios.get(`employees/without-priority-status`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
          params: {
            page: this.page,
            size: 1000,
          },
        });
        this.employeesWithoutPriorityStatus = data.rows;
      } catch (err) {
        this.$errorHandler(err);
      } finally {
        this.loading = false;
      }
    },

    async getPriorityStatuses() {
      this.loading = true;
      this.priorities = [];
      try {
        let { data } = await axios.get(`priority-statuses`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        });
        this.priorities = data;
      } catch (err) {
        this.$errorHandler(err);
      } finally {
        this.loading = false;
      }
    },

    async getFranchises() {
      this.loading = true;
      this.priorities = [];
      try {
        let { data } = await axios.get(`auth/current-user/authorized-franchises`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        });
        this.franchises = data;
      } catch (err) {
        this.$errorHandler(err);
      } finally {
        this.loading = false;
      }
    },

    handlePageChange(value) {
      this.page = value;
      this.getEmployees();
    },

    editItem(item) {
      this.singleAssign = true;
      this.editedIndex = this.employees.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.singleDelete = true;
      this.editedIndex = this.employees.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    async bulkAssignPriority() {
      this.loading = true;
      this.emp.employeeIds = [];
      for (const obj of this.selectedItems) {
        this.emp["employeeIds"].push(obj.Id);
        this.emp["priorityStatusId"] = this.priorityChange;
        console.log(this.emp);
      }
      try {
        await axios.put(`employees/bulk-priority-status-update`, this.emp, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        });
        this.$toast.success("Priority assigned successfully");
        this.close();
        this.emp.employeeIds = [];
        this.getEmployees();
        this.getPriorityStatuses();
        this.getWithoutPriorityStatus();
        this.singleAssign = false;
        this.selectedItems = [];
      } catch (err) {
        this.$errorHandler(err);
      } finally {
        this.loading = false;
      }
    },

    toggleSingleAssign() {
      this.singleAssign = false;
      this.dialog = true;
    },

    toggleSingleDelete() {
      this.singleDelete = false;
      this.dialogDelete = true;
    },

    async bulkRemovePriority() {
      this.loading = true;
      this.emp.employeeIds = [];
      for (const obj of this.selectedItems) {
        this.emp["employeeIds"].push(obj.Id);
      }
      try {
        await axios.put(`employees/bulk-priority-status-remove`, this.emp, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        });
        this.$toast.success("Priority removed successfully");
        this.close();
        this.emp.employeeIds = [];
        this.getEmployees();
        this.getPriorityStatuses();
        this.getWithoutPriorityStatus();
        this.emp.employeeIds = [];
        this.singleDelete = false;
        this.selectedItems = [];
      } catch (err) {
        this.$errorHandler(err);
      } finally {
        this.loading = false;
      }
      this.closeDelete();
    },

    async deleteItemConfirm() {
      this.loading = true;
      try {
        this.emp["employeeIds"].push(this.editedItem.Id);
        await axios.put(`employees/bulk-priority-status-remove`, this.emp, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        });
        this.$toast.success("Priority removed successfully");
        this.close();
        this.getEmployees();
        this.getPriorityStatuses();
      } catch (err) {
        this.$errorHandler(err);
      } finally {
        this.loading = false;
        this.closeDelete();
      }
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    async assignPriorityStatusToEmployee() {
      this.loading = true;
      try {
        await axios.put(
          `/employees/${this.editedItem.Id}/priority-statuses/${this.priorityChange}`,
          "",
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
          }
        );
        this.$toast.success("Priority assigned successfully");
        this.close();
        this.getEmployees();
        this.getPriorityStatuses();
        this.getWithoutPriorityStatus();
      } catch (err) {
        this.$errorHandler(err);
      } finally {
        this.loading = false;
      }
    },
    getFullFranchiseName(item) {
      return `${item.Name} ${item.Code}`;
    },
  },

  mounted() {
    this.getPriorityStatuses();
    this.getFranchises();
    this.getEmployees();
    this.getWithoutPriorityStatus();
  },
  computed: {
    assignFormTitle() {
      return this.singleAssign
        ? "For all areas, what priority should this employee have for appointment setting?"
        : "For all areas, what priority should these employees have for appointment setting?";
    },
    deleteFormTitle() {
      return this.singleDelete
        ? "Are you sure you want to remove this employee from roster?"
        : "Are you sure you want to remove these employees from roster?";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
};
</script>
